footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #4a4e69;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0;
  height: 60px;
}

.logo-footer {
  height: 50px;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.person-footer {
  margin: 5px 10px;
  text-align: center;
  width: 150px;
}

.footer-photo-git {
  height: 25px;
  width: 25px;
}

.member-name {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 0px;
}
